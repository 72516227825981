import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [~5,[2],[3]],
		"/(app)/article/[slug]": [~10,[2],[3]],
		"/(app)/invoice": [11,[2],[3]],
		"/(app)/sendCharger": [~12,[2],[3]],
		"/(app)/[qrcode=qrcode]": [~6,[2],[3]],
		"/(app)/[qrcode=qrcode]/[sessionId=sipHash24]": [~7,[2,4],[3]],
		"/(app)/[qrcode=qrcode]/[sessionId=sipHash24]/payment": [~8,[2,4],[3]],
		"/(app)/[qrcode=qrcode]/[sessionId=sipHash24]/status": [9,[2,4],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';