import { env } from '$env/dynamic/public';
import { PUBLIC_RELEASE_VERSION } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

Sentry.init({
  dsn: env.PUBLIC_SENTRY_DSN,
  environment: env.PUBLIC_SENTRY_ENV,
  release: PUBLIC_RELEASE_VERSION,
  tracesSampleRate: 0.5, // for a more precise config use tracesSampler
  integrations: [Sentry.breadcrumbsIntegration()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
